import React from "react"
import { graphql, navigate } from 'gatsby'


export default ({ data }) => {
    const post = data.markdownRemark;
    return (
        <div id="blogpost">
            <h1 className="title">
                {post.frontmatter.title}
                <div className="date">{post.frontmatter.date}</div>
                <div className="tags">{post.frontmatter.tags}</div>
            </h1>

            <div dangerouslySetInnerHTML={{ __html: post.html }} />

            <button className="backButton" onClick={() => 
                navigate('/#'+post.frontmatter.title.replace(/ /g,'-')) }>
            back</button>
        </div>
    )
}

export const query = graphql`
  query BlogPostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY")
        tags
      }
    }
  }
`
